import { IDigAdProviderScorecardConfig, IDigAdProviderScorecardBillboardConfig } from '../../../../_shared/models/models-index';
import { IMetricType, Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class DigAdProviderScorecardConfig implements IDigAdProviderScorecardConfig {
  public reportTitle = 'Digital Advertising Provider Scorecard';

  public scorecardMetrics: IDigAdProviderScorecardBillboardConfig[] = [
    {
      metric: Enums.digitalAdvertisingMetrics.percentageOfTotalEnrollment,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa
    },
    {
      metric: Enums.digitalAdvertisingMetrics.avgImpressions,
      metricFormatKey: constants.formatKeys.roundNumber
    },
    {
      metric: Enums.digitalAdvertisingMetrics.avgClickThroughRate,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa
    },
    {
      metric: Enums.digitalAdvertisingMetrics.avgEngagementRate,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa
    },
    {
      metric: Enums.digitalAdvertisingMetrics.avgActionRate,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa
    },
  ];
}
