export const environment = {
  production: false,
  hmr: false,
  debug: true,
  client: 'GM-US',
  hostName: 'gmus-analytics-staging.azurewebsites.net',
  environmentName: 'staging',
  appName: 'GM-US Analytics',
  ssoPassword: 'ShiftDigital#123',
  applicationInsights: {
    instrumentationKey: '070d356f-1010-425c-86d3-cfd2049db819'
  },
  defaultLocale: 'en',
  availableLocales: ['en'],
  timeZone: 'Eastern Standard Time',
  showLocaleSelector: 'true',
  numberOfMonthsToShowDefault: 12,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1,2],
  closeRateLookbackMonths: 3,
  defaultChartColors: ['#1b365c', '#47ddfc', '#A6A6A6', '#D9D9D9', '#CCCCCC'],
  baseApiUri: 'https://gmus-analytics-api-staging.azurewebsites.net/api',
  authUri: 'https://gmus-analytics-api-staging.azurewebsites.net/token',
  baseV5ApiUri: 'https://gmus-analytics-apiv5-staging.azurewebsites.net/api',
  retailerToolsBaseUri: '',
  retailerToolsIncludeAuth: false,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/gm-us/favicon.ico',
  loginRedirectUrl:  'https://preprod.vsp.gm.com/gmentsso/idpssoinit?metaAlias=/vsprealm/idp&spEntityID=analytics-staging.gmdealerdigital.com',
  logoutRedirectUrl: 'https://dealer.pp.gm.com/portal/us/Pages/default.aspx',
  sidebarDefaultOpen: false,
  useV5WebsiteOverview: false,
  v5Reports: ['WebsiteOverview','WebsiteScorecard','TradeInOverview','ChatOverview','DigitalAdvertisingTier2Dashboard','DigitalAdvertisingDashboard']
};
