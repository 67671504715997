import { IOrgLookupType } from '../../_shared/enums/enums';
import { IOrgConfig } from '../../_shared/models/models-index';

export class OrgConfig implements IOrgConfig {

  orgLookupTypes: Record<string, IOrgLookupType> = {
        'national': { name: 'National', orgLookupTypeId: 1, orgEntityType: 'national', default: true },
        'dma': { name: 'DMA', orgLookupTypeId: 2, orgEntityType: 'national', default: false },
  };

    public nationalOrgLookupTypeIds: number[] = [1,2];
    public lmaOrgLookupTypeIds: number[] = [];
    public virtual20OrgLookupTypeIds: number[] = [];
    public dealerGroupOrgLookupTypeIds: number[] = [];

  orgEntityType: Map<string, string> = new Map([
    ['orgcode3', 'orgcode3'],
    ['orgcode2', 'orgcode2'],
    ['orgcode1', 'orgcode1'],
    ['dealer', 'dealer'],
  ]);

  entityTypeName: Map<string, string> = new Map([
    ['orgcode3', 'Region'],
    ['orgcode2', 'Area'],
    ['orgcode1', 'District'],
    ['dealer', 'Dealer'],
  ]);

}
