import {
   IDefaultTableColumnConfig,
   IMultiLevelDualAxisLineGraphConfig,
   IWebsiteReferrerConfig,
   ICardHeaderConfig } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class WebsiteReferrerConfig implements IWebsiteReferrerConfig {
   reportTitle = 'Referrer Report';
   public metricDisplayModes = [
	{ name: 'POP', value: 'MOM' },
	{ name: 'YOY', value: 'YOY' }
];
   public benchmarkCardHeader: ICardHeaderConfig = {
      title: 'Summary Data',
      iconClass: 'fa-file',
      helpTextKey: constants.helpTextKeys.websiteReferrerSummaryHelp,
      helpTextTitle: 'Referrer Summary',
      exportName: 'Referrer Report - Summary Data',
      metricDisplayModes: ['POP', 'YOY']
   };

    public graphConfig: IMultiLevelDualAxisLineGraphConfig = {
      xAxisPropertyName: 'date',
      parentMetrics: [
				{
					id: Enums.websiteMetrics.totalVisits.metricId,
					displayName: 'Total Visits',
					propertyName: Enums.websiteMetrics.totalVisits.nameKey,
					metricFormatKey: constants.formatKeys.localeString
				},
				{
					id: Enums.websiteMetrics.uniqueVisits.metricId,
					displayName: 'Unique Visitors',
					propertyName: Enums.websiteMetrics.uniqueVisits.nameKey,
					metricFormatKey: constants.formatKeys.localeString
				},
				{
					id: Enums.websiteMetrics.salesPageViews.metricId,
					displayName: 'Retail KBAs',
					propertyName: 'salesPageViews',
					metricFormatKey: constants.formatKeys.localeString
				},
				{
					id: Enums.websiteMetrics.servicePageViews.metricId,
					displayName: 'Service KBAs',
					propertyName: Enums.websiteMetrics.servicePageViews.nameKey,
					metricFormatKey: constants.formatKeys.localeString
				},
				{
					id: Enums.websiteMetrics.pageViews.metricId,
					displayName: 'Total Page Views',
					propertyName: Enums.websiteMetrics.pageViews.nameKey,
					metricFormatKey: constants.formatKeys.localeString
				},
				{
					id: Enums.websiteMetrics.salesSessions.metricId,
					displayName: 'Retail KBA Visits',
					propertyName: 'salesSessions',
					metricFormatKey: constants.formatKeys.localeString
				},
				{
					id: Enums.websiteMetrics.serviceSessions.metricId,
					displayName: 'Service KBA Visits',
					propertyName: 'serviceSessions',
					metricFormatKey: constants.formatKeys.localeString
				},
				{
					id: Enums.websiteMetrics.salesSessionsConversionRate.metricId,
					displayName: 'Retail KBA Conversion Rate',
					propertyName: 'salesSessionsConversionRate',
					metricFormatKey: constants.formatKeys.percentageTwoDecimals
				},
				{
					id: Enums.websiteMetrics.serviceSessionsConversionRate.metricId,
					displayName: 'Service KBA Conversion Rate',
					propertyName: 'serviceSessionsConversionRate',
					metricFormatKey: constants.formatKeys.percentageTwoDecimals
				},
				{
					id: Enums.websiteMetrics.avgTimeOnSite.metricId,
					displayName: 'Time on Site',
					propertyName: Enums.websiteMetrics.avgTimeOnSite.nameKey,
					metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds
				},
				{
					id: Enums.websiteMetrics.formSubmissions.metricId,
					displayName: 'Form Submissions',
					propertyName: Enums.websiteMetrics.formSubmissions.nameKey,
					metricFormatKey: constants.formatKeys.localeString
				},
				{
					id: Enums.websiteMetrics.formConversionRate.metricId,
					displayName: 'Form Conversion Rate',
					propertyName: Enums.websiteMetrics.formConversionRate.nameKey,
					metricFormatKey: constants.formatKeys.percentageTwoDecimals
				},
				{
					id: Enums.websiteMetrics.clickToCalls.metricId,
					displayName: 'Click to Calls',
					propertyName: Enums.websiteMetrics.clickToCalls.nameKey,
					metricFormatKey: constants.formatKeys.localeString
				},
				{
					id: Enums.websiteMetrics.bounces.metricId,
					displayName: 'Bounces',
					propertyName: Enums.websiteMetrics.bounces.nameKey,
					metricFormatKey: constants.formatKeys.localeString
				},
				{
					id: Enums.websiteMetrics.bounceRate.metricId,
					displayName: 'Bounce Rate',
					propertyName: Enums.websiteMetrics.bounceRate.nameKey,
					metricFormatKey: constants.formatKeys.percentageTwoDecimals
				},
				{
					id: Enums.websiteMetrics.newVlpViews.metricId,
					displayName: 'New VSR Views',
					propertyName: 'vlpNewViews',
					metricFormatKey: constants.formatKeys.localeString
				},
				{
					id: Enums.websiteMetrics.usedVlpViews.metricId,
					displayName: 'Used VSR Views',
					propertyName: 'vlpUsedViews',
					metricFormatKey: constants.formatKeys.localeString
				},
				{
					id: Enums.websiteMetrics.mixedVlpViews.metricId,
					displayName: 'Mixed VSR Views',
					propertyName: 'vlpMixedViews',
					metricFormatKey: constants.formatKeys.localeString
				},
				{
					id: Enums.websiteMetrics.newVdpViews.metricId,
					displayName: 'New VDP Views',
					propertyName: 'vdpNewViews',
					metricFormatKey: constants.formatKeys.localeString
				},
				{
					id: Enums.websiteMetrics.usedVdpViews.metricId,
					displayName: 'Used VDP Views',
					propertyName: 'vdpUsedViews',
					metricFormatKey: constants.formatKeys.localeString
				},
				{
					id: Enums.websiteMetrics.cpoVdpViews.metricId,
					displayName: 'CPO VDP Views',
					propertyName: 'vdpCPOViews',
					metricFormatKey: constants.formatKeys.localeString
				},
				{
					id: Enums.websiteMetrics.totalVlpViews.metricId,
					displayName: 'Total VSR Views',
					propertyName: 'vlpTotalViews',
					metricFormatKey: constants.formatKeys.localeString
				},
				{
					id: Enums.websiteMetrics.totalVdpViews.metricId,
					displayName: 'Total VDP Views',
					propertyName: 'vdpTotalViews',
					metricFormatKey: constants.formatKeys.localeString
				},
				{
					id: Enums.websiteMetrics.hoursAndDirections.metricId,
					displayName: 'Hours & Directions',
					propertyName: 'hoursAndDirectionsViews',
					metricFormatKey: constants.formatKeys.localeString
				},
				{
					id: Enums.websiteMetrics.windowStickers.metricId,
					displayName: 'Window Stickers',
					propertyName: 'windowStickerClicks',
					metricFormatKey: constants.formatKeys.localeString
				},
				{
					id: Enums.websiteMetrics.onlineServiceScheduler.metricId,
					displayName: 'Online Service Scheduler',
					propertyName: 'serviceSchedulerViews',
					metricFormatKey: constants.formatKeys.localeString
				},
				{
					id: Enums.websiteMetrics.tireFinderViews.metricId,
					displayName: 'Tire Finder Views',
					propertyName: 'tireFinderViews',
					metricFormatKey: constants.formatKeys.localeString
				},
				{
					id: Enums.websiteMetrics.bannerEngagements.metricId,
					displayName: 'Masthead Engagements',
					propertyName: 'bannerClicks',
					metricFormatKey: constants.formatKeys.localeString
				},
				{
					id: Enums.websiteMetrics.engagements.metricId,
					displayName: 'Engagement',
					propertyName: Enums.websiteMetrics.engagements.nameKey,
					metricFormatKey: constants.formatKeys.localeString
				},
				{
					id: Enums.websiteMetrics.engagementRate.metricId,
					displayName: 'Engagement Rate',
					propertyName: Enums.websiteMetrics.engagementRate.nameKey,
					metricFormatKey: constants.formatKeys.percentageTwoDecimals
				},
				{
					id: Enums.websiteMetrics.actions.metricId,
					displayName: 'Actions',
					propertyName: Enums.websiteMetrics.actions.nameKey,
					metricFormatKey: constants.formatKeys.localeString
				},
				{
					id: Enums.websiteMetrics.actionRate.metricId,
					displayName: 'Action Rate',
					propertyName: Enums.websiteMetrics.actionRate.nameKey,
					metricFormatKey: constants.formatKeys.percentageTwoDecimals
				},
      ],
      childMetrics: [
         {
            id: Enums.channels.allChannels.channelId,
            displayName: Enums.channels.allChannels.channelName,
            propertyName: Enums.channels.allChannels.channelName,
            metricFormatKey: constants.formatKeys.entityDisplayName
         },
         {
            id: Enums.channels.brand.channelId,
            displayName: Enums.channels.brand.channelName,
            propertyName: Enums.channels.brand.channelName,
            metricFormatKey: constants.formatKeys.entityDisplayName
         },
         {
            id: Enums.channels.organicEmail.channelId,
            displayName: Enums.channels.organicEmail.channelName,
            propertyName: Enums.channels.organicEmail.channelName,
            metricFormatKey: constants.formatKeys.entityDisplayName
         },
         {
            id: Enums.channels.organicSearch.channelId,
            displayName: Enums.channels.organicSearch.channelName,
            propertyName: Enums.channels.organicSearch.channelName,
            metricFormatKey: constants.formatKeys.entityDisplayName
         },
         {
            id: Enums.channels.organicSocial.channelId,
            displayName: Enums.channels.organicSocial.channelName,
            propertyName: Enums.channels.organicSocial.channelName,
            metricFormatKey: constants.formatKeys.entityDisplayName
         },
         {
            id: Enums.channels.other.channelId,
            displayName: Enums.channels.other.channelName,
            propertyName: Enums.channels.other.channelName,
            metricFormatKey: constants.formatKeys.entityDisplayName
         },
         {
            id: Enums.channels.paidDisplay.channelId,
            displayName: Enums.channels.paidDisplay.channelName,
            propertyName: Enums.channels.paidDisplay.channelName,
            metricFormatKey: constants.formatKeys.entityDisplayName
         },
         {
            id: Enums.channels.paidEmail.channelId,
            displayName: Enums.channels.paidEmail.channelName,
            propertyName: Enums.channels.paidEmail.channelName,
            metricFormatKey: constants.formatKeys.entityDisplayName
         },
         {
            id: Enums.channels.paidSearch.channelId,
            displayName: Enums.channels.paidSearch.channelName,
            propertyName: Enums.channels.paidSearch.channelName,
            metricFormatKey: constants.formatKeys.entityDisplayName
         },
         {
            id: Enums.channels.paidVideo.channelId,
            displayName: Enums.channels.paidVideo.channelName,
            propertyName: Enums.channels.paidVideo.channelName,
            metricFormatKey: constants.formatKeys.entityDisplayName
         },
         {
            id: Enums.channels.thirdPartySites.channelId,
            displayName: Enums.channels.thirdPartySites.channelName,
            propertyName: Enums.channels.thirdPartySites.channelName,
            metricFormatKey: constants.formatKeys.entityDisplayName
         },
         {
            id: Enums.channels.typedBookmarked.channelId,
            displayName: Enums.channels.typedBookmarked.channelName,
            propertyName: Enums.channels.typedBookmarked.channelName,
            metricFormatKey: constants.formatKeys.entityDisplayName
         },
      ],

      defaultChildrenIds: [ Enums.channels.allChannels.channelId, Enums.channels.allChannels.channelId ],
      defaultParentIds: [ Enums.websiteMetrics.actions.metricId, Enums.websiteMetrics.engagements.metricId ],
      metricOneColorHexCode: '#5e91ea',
      metricTwoColorHexCode: '#343a40'
    };

    public summaryTableColumns: IDefaultTableColumnConfig[] = [
      {
         show: true,
         header: 'Referring Channel',
         columnDef: 'entity',
         isMom: false,
         isYoy: false,
         metricFormatKey: constants.formatKeys.entityDisplayName,
         clickable: false
			},
			//Total Visits
			{
				show: true,
				header: 'Total Visits',
				columnDef: 'totalVisits',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Total Visits',
				columnDef: 'totalVisitsPreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Total Visits',
				columnDef: 'totalVisitsPreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Total Visits POP',
				columnDef: 'totalVisitsMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Total Visits YOY',
				columnDef: 'totalVisitsYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//Unique Visitors
			{
				show: true,
				header: 'Unique Visitors',
				columnDef: 'uniqueVisits',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Unique Visitors',
				columnDef: 'uniqueVisitsPreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Unique Visitors',
				columnDef: 'uniqueVisitsPreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Unique Visitors POP',
				columnDef: 'uniqueVisitsMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Unique Visitors YOY',
				columnDef: 'uniqueVisitsYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//Sales Page Views
			{
				show: true,
				header: 'Retail KBAs',
				columnDef: 'salesPageViews',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Retail KBAs',
				columnDef: 'salesPageViewsPreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Sales Activities',
				columnDef: 'salesPageViewsPreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Retail KBAs POP',
				columnDef: 'salesPageViewsMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Retail KBAs YOY',
				columnDef: 'salesPageViewsYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//Service Page Views
			{
				show: true,
				header: 'Service KBAs',
				columnDef: 'servicePageViews',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Service KBAs',
				columnDef: 'servicePageViewsPreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Service KBAs',
				columnDef: 'servicePageViewsPreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Service KBAs POP',
				columnDef: 'servicePageViewsMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Service KBAs YOY',
				columnDef: 'servicePageViewsYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//Total Page Views
			{
				show: true,
				header: 'Total Page Views',
				columnDef: 'pageViews',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Total Page Views',
				columnDef: 'pageViewsPreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Total Page Views',
				columnDef: 'pageViewsPreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Total Page Views POP',
				columnDef: 'pageViewsMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Total Page Views YOY',
				columnDef: 'totalPageViewsYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			// Sales Sessions
			{
				show: true,
				header: 'Retail KBA Visits',
				columnDef: 'salesSessions',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Retail KBA Visits',
				columnDef: 'salesSessionsPreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Retail KBA Visits',
				columnDef: 'salesSessionsPreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Retail KBA Visits POP',
				columnDef: 'salesSessionsMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Retail KBA Visits YOY',
				columnDef: 'salesSessionsYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			// Service Sessions
			{
				show: true,
				header: 'Service KBA Visits',
				columnDef: 'serviceSessions',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Service KBA Visits',
				columnDef: 'serviceSessionsPreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Service KBA Visits',
				columnDef: 'serviceSessionsPreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Service KBA Visits POP',
				columnDef: 'serviceSessionsMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Service KBA Visits YOY',
				columnDef: 'serviceSessionsYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			// Sales Sessions Conversion Rate
			{
				show: true,
				header: 'Retail KBA Conversion Rate',
				columnDef: 'salesSessionsConversionRate',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Retail KBA Conversion Rate',
				columnDef: 'salesSessionsConversionRatePreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Retail KBA Conversion Rate',
				columnDef: 'salesSessionsConversionRatePreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Retail KBA Conversion Rate POP',
				columnDef: 'salesSessionsConversionRateMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Retail KBA Conversion Rate YOY',
				columnDef: 'salesSessionsConversionRateYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			// Service Sessions Conversion Rate
			{
				show: true,
				header: 'Service KBA Conversion Rate',
				columnDef: 'serviceSessionsConversionRate',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Service KBA Conversion Rate',
				columnDef: 'serviceSessionsConversionRatePreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Service KBA Conversion Rate',
				columnDef: 'serviceSessionsConversionRatePreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Service KBA Conversion Rate POP',
				columnDef: 'serviceSessionsConversionRateMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Service KBA Conversion Rate YOY',
				columnDef: 'serviceSessionsConversionRateYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//Time on Site
			{
				show: true,
				header: 'Time on Site',
				columnDef: 'averageTimeOnSite',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Time on Site',
				columnDef: 'averageTimeOnSitePreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Time on Site',
				columnDef: 'averageTimeOnSitePreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds,
				clickable: false
			},
			{
				show: false,
				header: 'Time on Site POP',
				columnDef: 'averageTimeOnSiteMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Time on Site YOY',
				columnDef: 'averageTimeOnSiteYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//Form Submissions
			{
				show: true,
				header: 'Form Submissions',
				columnDef: 'formSubmissions',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Form Submissions',
				columnDef: 'formSubmissionsPreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Form Submissions',
				columnDef: 'formSubmissionsPreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Form Submissions POP',
				columnDef: 'formSubmissionsMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Form Submissions YOY',
				columnDef: 'formSubmissionsYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//Form Conversion Rate
			{
				show: true,
				header: 'Form Conversion Rate',
				columnDef: 'formConversionRate',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Form Conversion Rate',
				columnDef: 'formConversionRatePreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Form Conversion Rate',
				columnDef: 'formConversionRatePreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Form Conversion Rate POP',
				columnDef: 'formConversionRateMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Form Conversion Rate YOY',
				columnDef: 'formConversionRateYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//Click to Calls
			{
				show: true,
				header: 'Click to Calls',
				columnDef: 'clickToCalls',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Click to Calls',
				columnDef: 'clickToCallsPreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Click to Calls',
				columnDef: 'clickToCallsPreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Click to Calls POP',
				columnDef: 'clickToCallsMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Click to Calls YOY',
				columnDef: 'clickToCallsYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//Bounces
			{
				show: true,
				header: 'Bounces',
				columnDef: 'bounces',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Bounces',
				columnDef: 'bouncesPreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Bounces',
				columnDef: 'bouncesPreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Bounces POP',
				columnDef: 'bouncesMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Bounces YOY',
				columnDef: 'bouncesYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//Bounce Rate
			{
				show: true,
				header: 'Bounce Rate',
				columnDef: 'bounceRate',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Bounce Rate',
				columnDef: 'bounceRatePreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Bounce Rate',
				columnDef: 'bounceRatePreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Bounce Rate POP',
				columnDef: 'bounceRateMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Bounce Rate YOY',
				columnDef: 'bounceRateYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//New VLP Views
			{
				show: true,
				header: 'New VSR Views',
				columnDef: 'vlpNewViews',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period New VSR Views',
				columnDef: 'vlpNewViewsPreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year New VSR Views',
				columnDef: 'vlpNewViewsPreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'New VSR Views POP',
				columnDef: 'vlpNewViewsMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'New VSR Views YOY',
				columnDef: 'vlpNewViewsYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//Used VLP Views
			{
				show: true,
				header: 'Used VSR Views',
				columnDef: 'vlpUsedViews',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Used VSR Views',
				columnDef: 'vlpUsedViewsPreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Used VSR Views',
				columnDef: 'vlpUsedViewsPreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Used VSR Views POP',
				columnDef: 'vlpUsedViewsMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Used VSR Views YOY',
				columnDef: 'vlpUsedViewsYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//Mixed VLP Views
			{
				show: true,
				header: 'Mixed VSR Views',
				columnDef: 'vlpMixedViews',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Mixed VSR Views',
				columnDef: 'vlpMixedViewsPreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Mixed VSR Views',
				columnDef: 'vlpMixedViewsPreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Mixed VSR Views POP',
				columnDef: 'vlpMixedViewsMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Mixed VSR Views YOY',
				columnDef: 'vlpMixedViewsYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//New VDP Views
			{
				show: true,
				header: 'New VDP Views',
				columnDef: 'vdpNewViews',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period New VDP Views',
				columnDef: 'vdpNewViewsPreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year New VDP Views',
				columnDef: 'vdpNewViewsPreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'New VDP Views POP',
				columnDef: 'vdpNewViewsMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'New VDP Views YOY',
				columnDef: 'vdpNewViewsYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//Used VDP Views
			{
				show: true,
				header: 'Used VDP Views',
				columnDef: 'vdpUsedViews',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Used VDP Views',
				columnDef: 'vdpUsedViewsPreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Used VDP Views',
				columnDef: 'vdpUsedViewsPreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Used VDP Views POP',
				columnDef: 'vdpUsedViewsMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Used VDP Views YOY',
				columnDef: 'vdpUsedViewsYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//CPO VDP Views
			{
				show: true,
				header: 'CPO VDP Views',
				columnDef: 'vdpCPOViews',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period CPO VDP Views',
				columnDef: 'vdpCPOViewsPreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year CPO VDP Views',
				columnDef: 'vdpCPOViewsPreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'CPO VDP Views POP',
				columnDef: 'vdpCPOViewsMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'CPO VDP Views YOY',
				columnDef: 'vdpCPOViewsYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//Total VLP Views
			{
				show: true,
				header: 'Total VSR Views',
				columnDef: 'vlpTotalViews',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Total VSR Views',
				columnDef: 'vlpTotalViewsPreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Total VSR Views',
				columnDef: 'vlpTotalViewsPreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Total VSR Views POP',
				columnDef: 'vlpTotalViewsMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Total VSR Views YOY',
				columnDef: 'vlpTotalViewsYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//Total VDP Views
			{
				show: true,
				header: 'Total VDP Views',
				columnDef: 'vdpTotalViews',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Total VDP Views',
				columnDef: 'vdpTotalViewsPreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Total VDP Views',
				columnDef: 'vdpTotalViewsPreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Total VDP Views POP',
				columnDef: 'vdpTotalViewsMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Total VDP Views YOY',
				columnDef: 'vdpTotalViewsYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//Hours & Directions
			{
				show: true,
				header: 'Hours & Directions',
				columnDef: 'hoursAndDirectionsViews',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Hours & Directions',
				columnDef: 'hoursAndDirectionsViewsPreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Hours & Directions',
				columnDef: 'hoursAndDirectionsViewsPreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Hours & Directions POP',
				columnDef: 'hoursAndDirectionsViewsMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Hours & Directions YOY',
				columnDef: 'hoursAndDirectionsViewsYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//Window Stickers
			{
				show: true,
				header: 'Window Stickers',
				columnDef: 'windowStickerClicks',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Window Stickers',
				columnDef: 'windowStickerClicksPreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Window Stickers',
				columnDef: 'windowStickerClicksPreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Window Stickers POP',
				columnDef: 'windowStickerClicksMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Window Stickers YOY',
				columnDef: 'windowStickerClicksYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//Online Service Scheduler
			{
				show: true,
				header: 'Online Service Scheduler',
				columnDef: 'serviceSchedulerViews',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Online Service Scheduler',
				columnDef: 'serviceSchedulerViewsPreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Online Service Scheduler',
				columnDef: 'serviceSchedulerViewsPreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Online Service Scheduler POP',
				columnDef: 'serviceSchedulerViewsMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Online Service Scheduler YOY',
				columnDef: 'serviceSchedulerViewsYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//Tire Finder Views
			{
				show: true,
				header: 'Tire Finder Views',
				columnDef: 'tireFinderViews',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Tire Finder Views',
				columnDef: 'tireFinderViewsPreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Tire Finder Views',
				columnDef: 'tireFinderViewsPreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Tire Finder Views POP',
				columnDef: 'tireFinderViewsMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Tire Finder Views YOY',
				columnDef: 'tireFinderViewsYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//Banner Engagements
			{
				show: true,
				header: 'Masthead Engagements',
				columnDef: 'bannerClicks',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Masthead Engagements',
				columnDef: 'bannerClicksPreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Masthead Engagements',
				columnDef: 'bannerClicksPreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Masthead Engagements POP',
				columnDef: 'bannerClicksMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Masthead Engagements YOY',
				columnDef: 'bannerClicksYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//Engagement
			{
				show: true,
				header: 'Engagement',
				columnDef: 'engagements',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Engagement',
				columnDef: 'engagementsPreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Engagement',
				columnDef: 'engagementsPreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Engagement POP',
				columnDef: 'engagementsMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Engagement YOY',
				columnDef: 'engagementsYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//Engagement Rate
			{
				show: true,
				header: 'Engagement Rate',
				columnDef: 'engagementRate',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Engagement Rate',
				columnDef: 'engagementRatePreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Engagement Rate',
				columnDef: 'engagementRatePreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Engagement Rate POP',
				columnDef: 'engagementRateMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Engagement Rate YOY',
				columnDef: 'engagementRateYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//Actions
			{
				show: true,
				header: 'Actions',
				columnDef: 'actions',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Actions',
				columnDef: 'actionsPreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Actions',
				columnDef: 'actionsPreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.localeString,
				clickable: false
			},
			{
				show: false,
				header: 'Actions POP',
				columnDef: 'actionsMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Actions YOY',
				columnDef: 'actionsYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			//Action Rate
			{
				show: true,
				header: 'Action Rate',
				columnDef: 'actionRate',
				isMom: false,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Period Action Rate',
				columnDef: 'actionRatePreviousMTD',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Prev. Year Action Rate',
				columnDef: 'actionRatePreviousYear',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Action Rate POP',
				columnDef: 'actionRateMOM',
				isMom: true,
				isYoy: false,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
			{
				show: false,
				header: 'Action Rate YOY',
				columnDef: 'actionRateYOY',
				isMom: false,
				isYoy: true,
				metricFormatKey: constants.formatKeys.percentageTwoDecimals,
				clickable: false
			},
		];
}
