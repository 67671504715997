import {
    IDefaultTableColumnConfig,
    IWebsiteVehicleConfig,
    IMultiLevelDualAxisLineGraphConfig,
    ICardHeaderConfig } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class WebsiteVehicleConfig implements IWebsiteVehicleConfig {
    reportTitle = 'Vehicle Summary';
    public metricDisplayModes = [
		{ name: 'POP', value: 'MOM' },
		{ name: 'YOY', value: 'YOY' }
	];
    
    public benchmarkCardHeader: ICardHeaderConfig = {
        title: 'Summary Data',
        iconClass: 'fa-file',
        helpTextKey: constants.helpTextKeys.websiteVdpSummaryHelp,
        helpTextTitle: 'Vehicle Summary',
        exportName: 'Vehicle Summary - Summary Data',
        metricDisplayModes: ['POP', 'YOY']
    };

    public graphConfig: IMultiLevelDualAxisLineGraphConfig = {
        xAxisPropertyName: 'date',
        parentMetrics: [
            {
                id: Enums.websiteMetrics.newVdpViews.metricId,
                displayName: Enums.websiteMetrics.newVdpViews.name,
                propertyName: 'newVdpViews',
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.websiteMetrics.usedVdpViews.metricId,
                displayName: Enums.websiteMetrics.usedVdpViews.name,
                propertyName: 'usedVdpViews',
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.websiteMetrics.cpoVdpViews.metricId,
                displayName: Enums.websiteMetrics.cpoVdpViews.name,
                propertyName: 'cpoVdpViews',
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.websiteMetrics.otherVdpViews.metricId,
                displayName: Enums.websiteMetrics.otherVdpViews.name,
                propertyName: 'otherVdpViews',
                metricFormatKey: constants.formatKeys.localeString
            },
            {
                id: Enums.websiteMetrics.totalVdpViews.metricId,
                displayName: Enums.websiteMetrics.totalVdpViews.name,
                propertyName: 'totalVdpViews',
                metricFormatKey: constants.formatKeys.localeString
            },
        ],
        childMetrics: [], // this needs to be data driven

        defaultChildrenIds: [ 'All Models', 'All Models' ],
        defaultParentIds: [ Enums.websiteMetrics.newVdpViews.metricId, Enums.websiteMetrics.usedVdpViews.metricId ],
        metricOneColorHexCode: '#5e91ea',
        metricTwoColorHexCode: '#343a40'
    };

    public summaryTableColumns: IDefaultTableColumnConfig[] = [
        {
            show: true,
            header: 'Vehicle Make',
            columnDef: 'entity',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.entityDisplayName,
            clickable: false
        },
        {
            show: true,
            header: 'New VDP Views',
            columnDef: 'newVdpViews',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Prev. Period New VDP Views',
            columnDef: 'newVdpViewsPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'New VDP Views POP',
            columnDef: 'newVdpViewsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year New VDP Views',
            columnDef: 'newVdpViewsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'New VDP Views YOY',
            columnDef: 'newVdpViewsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: true,
            header: 'Used VDP Views',
            columnDef: 'usedVdpViews',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Prev. Period Used VDP Views',
            columnDef: 'usedVdpViewsPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Used VDP Views POP',
            columnDef: 'usedVdpViewsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Used VDP Views',
            columnDef: 'usedVdpViewsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Used VDP Views YOY',
            columnDef: 'usedVdpViewsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: true,
            header: 'CPO VDP Views',
            columnDef: 'cpoVdpViews',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Prev. Period CPO VDP Views',
            columnDef: 'cpoVdpViewsPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false

        },
        {
            show: false,
            header: 'CPO VDP Views POP',
            columnDef: 'cpoVdpViewsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year CPO VDP Views',
            columnDef: 'cpoVdpViewsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'CPO VDP Views YOY',
            columnDef: 'cpoVdpViewsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: true,
            header: 'Other VDP Views',
            columnDef: 'otherVdpViews',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Prev. Period Other VDP Views',
            columnDef: 'otherVdpViewsPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false

        },
        {
            show: false,
            header: 'Other VDP Views POP',
            columnDef: 'otherVdpViewsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Other VDP Views',
            columnDef: 'otherVdpViewsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Other VDP Views YOY',
            columnDef: 'otherVdpViewsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: true,
            header: 'Total VDP Views',
            columnDef: 'totalVdpViews',
            isMom: false,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Prev. Period Total VDP Views',
            columnDef: 'totalVdpViewsPreviousMTD',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Total VDP Views POP',
            columnDef: 'totalVdpViewsMOM',
            isMom: true,
            isYoy: false,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
        {
            show: false,
            header: 'Prev. Year Total VDP Views',
            columnDef: 'totalVdpViewsPreviousYear',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.localeString, clickable: false
        },
        {
            show: false,
            header: 'Total VDP Views YOY',
            columnDef: 'totalVdpViewsYOY',
            isMom: false,
            isYoy: true,
            metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
        },
    ];
}
