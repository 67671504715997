import {
    IWebsiteScorecardConfig, IWebsiteScorecardCategoryConfig
} from '../../../../_shared/models/models-index';
import * as constants from '../../../../_shared/constants/constants';

export class WebsiteScorecardConfig implements IWebsiteScorecardConfig {

    public reportTitle = 'Website Scorecard';
    public previousDateColumnDisplayOverrides =
        {
            previousMonthDisplay: 'Prev. Period',
            previousMTDDisplay: 'Prev. Period',
            momDisplay: 'POP'
        };
    public useChevronTrendIcon = true;
    public scorecards: IWebsiteScorecardCategoryConfig[] = [
        {
            category: 'Retail KBAs',
            billboards: [
                {
                    title: 'Retail KBA Conversion Rate',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'salesSessionsConversionRatePreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    iconClass: 'fa-user-o fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'salesSessionsConversionRate',
                    metricMOMPropertyName: 'salesSessionsConversionRateMOM',
                    metricYOYPropertyName: 'salesSessionsConversionRateYOY',
                    metricPreviousMonthPropertyName: 'salesSessionsConversionRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'salesSessionsConversionRatePreviousMTD',
                    metricPreviousYearPropertyName: 'salesSessionsConversionRatePreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgSalesSessionsConversionRate',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'salesSessionsConversionRateMOM',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    helpTextKey: 'WebsiteScorecardSalesSessionsConversionRateHelp',
                    helpTextTitle: 'Retail KBA Conversion Rate',
                    scoreCardCategory: 'Retail KBAs'
                },
                {
                    title: 'Form Submissions',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'formSubmissionsPreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    iconClass: 'fa-file-text-o fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'formSubmissions',
                    metricMOMPropertyName: 'formSubmissionsMOM',
                    metricYOYPropertyName: 'formSubmissionsYOY',
                    metricPreviousMonthPropertyName: 'formSubmissionsPreviousMonth',
                    metricPreviousMTDPropertyName: 'formSubmissionsPreviousMTD',
                    metricPreviousYearPropertyName: 'formSubmissionsPreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgFormSubmissions',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'formSubmissionsMOM',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    helpTextKey: 'WebsiteScorecardFormSubmissionsHelp',
                    helpTextTitle: 'Form Submissions',
                    scoreCardCategory: 'Retail KBA\'s'
                },
                {
                    title: 'Click to Calls',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'clickToCallsPreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    iconClass: 'fa-phone fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'clickToCalls',
                    metricMOMPropertyName: 'clickToCallsMOM',
                    metricYOYPropertyName: 'clickToCallsYOY',
                    metricPreviousMonthPropertyName: 'clickToCallsPreviousMonth',
                    metricPreviousMTDPropertyName: 'clickToCallsPreviousMTD',
                    metricPreviousYearPropertyName: 'clickToCallsPreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgClickToCalls',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'clickToCallsMOM',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    helpTextKey: 'WebsiteScorecardClickToCallsHelp',
                    helpTextTitle: 'Click to Calls',
                    scoreCardCategory: 'Retail KBA\'s'
                },
                {
                    title: 'Total VSR Views',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'vlpTotalViewsPreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    iconClass: 'fa-eye fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'vlpTotalViews',
                    metricMOMPropertyName: 'vlpTotalViewsMOM',
                    metricYOYPropertyName: 'vlpTotalViewsYOY',
                    metricPreviousMonthPropertyName: 'vlpTotalViewsPreviousMonth',
                    metricPreviousMTDPropertyName: 'vlpTotalViewsPreviousMTD',
                    metricPreviousYearPropertyName: 'vlpTotalViewsPreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgVlpTotalViews',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'vlpTotalViewsMOM',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    helpTextKey: 'WebsiteScorecardVsrTotalViewsHelp',
                    helpTextTitle: 'Total VSR Views',
                    scoreCardCategory: 'Retail KBA\'s'
                },
                {
                    title: 'Total VDP Views',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'vdpTotalViewsPreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    iconClass: 'fa-eye fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'vdpTotalViews',
                    metricMOMPropertyName: 'vdpTotalViewsMOM',
                    metricYOYPropertyName: 'vdpTotalViewsYOY',
                    metricPreviousMonthPropertyName: 'vdpTotalViewsPreviousMonth',
                    metricPreviousMTDPropertyName: 'vdpTotalViewsPreviousMTD',
                    metricPreviousYearPropertyName: 'vdpTotalViewsPreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgVdpTotalViews',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'vdpTotalViewsMOM',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    helpTextKey: 'WebsiteScorecardVdpTotalViewssHelp',
                    helpTextTitle: 'Total VDP Views',
                    scoreCardCategory: 'Retail KBA\'s'
                },
                {
                    title: 'Hours and Directions',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'hoursAndDirectionsViewsPreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    iconClass: 'fa-eye fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'hoursAndDirectionsViews',
                    metricMOMPropertyName: 'hoursAndDirectionsViewsMOM',
                    metricYOYPropertyName: 'hoursAndDirectionsViewsYOY',
                    metricPreviousMonthPropertyName: 'hoursAndDirectionsViewsPreviousMTD',
                    metricPreviousMTDPropertyName: 'hoursAndDirectionsViewsPreviousMTD',
                    metricPreviousYearPropertyName: 'hoursAndDirectionsViewsPreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgHoursAndDirectionsViews',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'hoursAndDirectionsViewsMOM',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    helpTextKey: 'WebsiteScorecardHoursAndDirectionsHelp',
                    helpTextTitle: 'Hours and Directions',
                    scoreCardCategory: 'Retail KBA\'s'
                },
                {
                    title: 'Window Stickers',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'windowStickerClicksPreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    iconClass: 'fa-eye fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'windowStickerClicks',
                    metricMOMPropertyName: 'windowStickerClicksMOM',
                    metricYOYPropertyName: 'windowStickerClicksYOY',
                    metricPreviousMonthPropertyName: 'windowStickerClicksPreviousMTD',
                    metricPreviousMTDPropertyName: 'windowStickerClicksPreviousMTD',
                    metricPreviousYearPropertyName: 'windowStickerClicksPreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgWindowStickerClicks',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'windowStickerClicksMOM',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    helpTextKey: 'WebsiteScorecardWindowStickersHelp',
                    helpTextTitle: 'Window Stickers',
                    scoreCardCategory: 'Retail KBA\'s'
                }
            ],
        },
        {
            category: 'Service KBAs',
            billboards: [
                {
                    title: 'Service KBA Conversion Rate',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'serviceSessionsConversionRatePreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    iconClass: 'fa-user-o fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'serviceSessionsConversionRate',
                    metricMOMPropertyName: 'serviceSessionsConversionRateMOM',
                    metricYOYPropertyName: 'serviceSessionsConversionRateYOY',
                    metricPreviousMonthPropertyName: 'serviceSessionsConversionRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'serviceSessionsConversionRatePreviousMTD',
                    metricPreviousYearPropertyName: 'serviceSessionsConversionRatePreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgServiceSessionsConversionRate',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'serviceSessionsConversionRateMOM',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    helpTextKey: 'WebsiteScorecardServiceSessionsConversionRateHelp',
                    helpTextTitle: 'Service KBA Conversion Rate',
                    scoreCardCategory: 'Service KBAs'
              },
              {
                    title: 'Click to Calls',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'clickToCallsPreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    iconClass: 'fa-phone fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'clickToCalls',
                    metricMOMPropertyName: 'clickToCallsMOM',
                    metricYOYPropertyName: 'clickToCallsYOY',
                    metricPreviousMonthPropertyName: 'clickToCallsPreviousMonth',
                    metricPreviousMTDPropertyName: 'clickToCallsPreviousMTD',
                    metricPreviousYearPropertyName: 'clickToCallsPreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgClickToCalls',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'clickToCallsMOM',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    helpTextKey: 'WebsiteScorecardClickToCallsHelp',
                    helpTextTitle: 'Click to Calls',
                    scoreCardCategory: 'Service KBA\'s'
                },
                {
                    title: 'Hours and Directions',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'hoursAndDirectionsViewsPreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    iconClass: 'fa-eye fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'hoursAndDirectionsViews',
                    metricMOMPropertyName: 'hoursAndDirectionsViewsMOM',
                    metricYOYPropertyName: 'hoursAndDirectionsViewsYOY',
                    metricPreviousMonthPropertyName: 'hoursAndDirectionsViewsPreviousMTD',
                    metricPreviousMTDPropertyName: 'hoursAndDirectionsViewsPreviousMTD',
                    metricPreviousYearPropertyName: 'hoursAndDirectionsViewsPreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgHoursAndDirectionsViews',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'hoursAndDirectionsViewsMOM',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    helpTextKey: 'WebsiteScorecardHoursAndDirectionsHelp',
                    helpTextTitle: 'Hours and Directions',
                    scoreCardCategory: 'Service KBA\'s'
                },
                {
                    title: 'Tire Finder Views',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'tireFinderViewsPreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    iconClass: 'fa-eye fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'tireFinderViews',
                    metricMOMPropertyName: 'tireFinderViewsMOM',
                    metricYOYPropertyName: 'tireFinderViewsYOY',
                    metricPreviousMonthPropertyName: 'tireFinderViewsPreviousMTD',
                    metricPreviousMTDPropertyName: 'tireFinderViewsPreviousMTD',
                    metricPreviousYearPropertyName: 'tireFinderViewsPreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgTireFinderViews',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'tireFinderViewsMOM',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    helpTextKey: 'WebsiteScorecardTireFinderViewsHelp',
                    helpTextTitle: 'Tire Finder Views',
                    scoreCardCategory: 'Service KBA\'s'
                },
                {
                    title: 'Online Service Scheduler',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'serviceSchedulerViewsPreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    iconClass: 'fa-eye fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'serviceSchedulerViews',
                    metricMOMPropertyName: 'serviceSchedulerViewsMOM',
                    metricYOYPropertyName: 'serviceSchedulerViewsYOY',
                    metricPreviousMonthPropertyName: 'serviceSchedulerViewsPreviousMTD',
                    metricPreviousMTDPropertyName: 'serviceSchedulerViewsPreviousMTD',
                    metricPreviousYearPropertyName: 'serviceSchedulerViewsPreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgServiceSchedulerViews',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'serviceSchedulerViewsMOM',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    helpTextKey: 'WebsiteScorecardServiceSchedulerViewsHelp',
                    helpTextTitle: 'Online Service Scheduler',
                    scoreCardCategory: 'Service KBA\'s'
                }
            ],
        },
        {
            category: 'Volume',
            billboards: [
                {
                    title: 'Visits',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'totalVisitsPreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    iconClass: 'fa-user-o fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'totalVisits',
                    metricMOMPropertyName: 'totalVisitsMOM',
                    metricYOYPropertyName: 'totalVisitsYOY',
                    metricPreviousMonthPropertyName: 'totalVisitsPreviousMonth',
                    metricPreviousMTDPropertyName: 'totalVisitsPreviousMTD',
                    metricPreviousYearPropertyName: 'totalVisitsPreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgTotalVisits',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'totalVisitsMOM',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    helpTextKey: 'WebsiteScorecardTotalVisitsHelp',
                    helpTextTitle: 'Visits',
                    scoreCardCategory: 'Volume'
                },
                {
                    title: 'Unique Visitors',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'uniqueVisitsPreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    iconClass: 'fa-user-circle-o fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'uniqueVisits',
                    metricMOMPropertyName: 'uniqueVisitsMOM',
                    metricYOYPropertyName: 'uniqueVisitsYOY',
                    metricPreviousMonthPropertyName: 'uniqueVisitsPreviousMonth',
                    metricPreviousMTDPropertyName: 'uniqueVisitsPreviousMTD',
                    metricPreviousYearPropertyName: 'uniqueVisitsPreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgUniqueVisits',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'uniqueVisitsMOM',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    helpTextKey: 'WebsiteScorecardUniqueVisitsHelp',
                    helpTextTitle: 'Unique Visitors',
                    scoreCardCategory: 'Volume'
                },
                {
                    title: 'Page Views',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'pageViewsPreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    iconClass: 'fa-eye fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'pageViews',
                    metricMOMPropertyName: 'pageViewsMOM',
                    metricYOYPropertyName: 'pageViewsYOY',
                    metricPreviousMonthPropertyName: 'pageViewsPreviousMonth',
                    metricPreviousMTDPropertyName: 'pageViewsPreviousMTD',
                    metricPreviousYearPropertyName: 'pageViewsPreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgPageViews',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'pageViewsMOM',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    helpTextKey: 'WebsiteScorecardPageViewsHelp',
                    helpTextTitle: 'Page Views',
                    scoreCardCategory: 'Volume'
                }
            ],
        },
        {
            category: 'Engagements',
            billboards: [
                {
                    title: 'Engagement Rate',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'engagementRatePreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    iconClass: 'fa-commenting-o fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'engagementRate',
                    metricMOMPropertyName: 'engagementRateMOM',
                    metricYOYPropertyName: 'engagementRateYOY',
                    metricPreviousMonthPropertyName: 'engagementRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'engagementRatePreviousMTD',
                    metricPreviousYearPropertyName: 'engagementRatePreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgEngagementRate',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'engagementRateMOM',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    helpTextKey: 'WebsiteScorecardEngagementRateHelp',
                    helpTextTitle: 'Engagement Rate',
                    scoreCardCategory: 'Engagements'
                },
                {
                    title: 'Avg Time On Site',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'averageTimeOnSitePreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds,
                    iconClass: 'fa-clock-o fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'averageTimeOnSite',
                    metricMOMPropertyName: 'averageTimeOnSiteMOM',
                    metricYOYPropertyName: 'averageTimeOnSiteYOY',
                    metricPreviousMonthPropertyName: 'averageTimeOnSitePreviousMonth',
                    metricPreviousMTDPropertyName: 'averageTimeOnSitePreviousMTD',
                    metricPreviousYearPropertyName: 'averageTimeOnSitePreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgAvgTimeOnSite',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'averageTimeOnSiteMOM',
                    metricFormatKey: constants.formatKeys.minutesTimeStringFromSeconds,
                    helpTextKey: 'WebsiteScorecardTimeOnSiteHelp',
                    helpTextTitle: 'Avg. Time On Site',
                    scoreCardCategory: 'Engagements'
                },
                {
                    title: 'VDP Views',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'vdpTotalViewsPreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    iconClass: 'fa-eye fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'vdpTotalViews',
                    metricMOMPropertyName: 'vdpTotalViewsMOM',
                    metricYOYPropertyName: 'vdpTotalViewsYOY',
                    metricPreviousMonthPropertyName: 'vdpTotalViewsPreviousMonth',
                    metricPreviousMTDPropertyName: 'vdpTotalViewsPreviousMTD',
                    metricPreviousYearPropertyName: 'vdpTotalViewsPreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgVdpViews',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'vdpTotalViewsMOM',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    helpTextKey: 'WebsiteScorecardVDPViewsHelp',
                    helpTextTitle: 'VDP Views',
                    scoreCardCategory: 'Engagements'
                },
                {
                    title: 'Bounces',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'bouncesPreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    iconClass: 'fa-commenting-o fa-primary',
                    reverseMetric: true,
                    metricCurrentPropertyName: 'bounces',
                    metricMOMPropertyName: 'bouncesMOM',
                    metricYOYPropertyName: 'bouncesYOY',
                    metricPreviousMonthPropertyName: 'bouncesPreviousMonth',
                    metricPreviousMTDPropertyName: 'bouncesPreviousMTD',
                    metricPreviousYearPropertyName: 'bouncesPreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgBounces',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'bouncesMOM',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    helpTextKey: 'WebsiteScorecardBouncesHelp',
                    helpTextTitle: 'Bounces',
                    scoreCardCategory: 'Engagements'
                },
                {
                    title: 'Bounce Rate',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'bounceRatePreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    iconClass: 'fa-commenting-o fa-primary',
                    reverseMetric: true,
                    metricCurrentPropertyName: 'bounceRate',
                    metricMOMPropertyName: 'bounceRateMOM',
                    metricYOYPropertyName: 'bounceRateYOY',
                    metricPreviousMonthPropertyName: 'bounceRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'bounceRatePreviousMTD',
                    metricPreviousYearPropertyName: 'bounceRatePreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgBounceRate',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'bounceRateMOM',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    helpTextKey: 'WebsiteScorecardBounceRateHelp',
                    helpTextTitle: 'Bounce Rate',
                    scoreCardCategory: 'Engagements'
                },
            ]
        },
        {
            category: 'Actions',
            billboards: [
                  {
                    title: 'Action Rate',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'actionRatePreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    iconClass: 'fa-files-o fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'actionRate',
                    metricMOMPropertyName: 'actionRateMOM',
                    metricYOYPropertyName: 'actionRateYOY',
                    metricPreviousMonthPropertyName: 'actionRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'actionRatePreviousMTD',
                    metricPreviousYearPropertyName: 'actionRatePreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgActiontRate',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'engagementRateMOM',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    helpTextKey: 'WebsiteScorecardActionRateHelp',
                    helpTextTitle: 'Action Rate',
                    scoreCardCategory: 'Actions'
                },
                {
                    title: 'Form Submissions',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'formSubmissionsPreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    iconClass: 'fa-file-text-o fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'formSubmissions',
                    metricMOMPropertyName: 'formSubmissionsMOM',
                    metricYOYPropertyName: 'formSubmissionsYOY',
                    metricPreviousMonthPropertyName: 'formSubmissionsPreviousMonth',
                    metricPreviousMTDPropertyName: 'formSubmissionsPreviousMTD',
                    metricPreviousYearPropertyName: 'formSubmissionsPreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgFormSubmissions',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'formSubmissionsMOM',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    helpTextKey: 'WebsiteScorecardFormSubmissionsHelp',
                    helpTextTitle: 'Form Submissions',
                    scoreCardCategory: 'Actions'
                },
                {
                    title: 'Click to Calls',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'clickToCallsPreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    iconClass: 'fa-phone fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'clickToCalls',
                    metricMOMPropertyName: 'clickToCallsMOM',
                    metricYOYPropertyName: 'clickToCallsYOY',
                    metricPreviousMonthPropertyName: 'clickToCallsPreviousMonth',
                    metricPreviousMTDPropertyName: 'clickToCallsPreviousMTD',
                    metricPreviousYearPropertyName: 'clickToCallsPreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgClickToCalls',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'clickToCallsMOM',
                    metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
                    helpTextKey: 'WebsiteScorecardClickToCallsHelp',
                    helpTextTitle: 'Click to Calls',
                    scoreCardCategory: 'Actions'
                }
            ]
        },
        {
            category: 'Conversion Rates',
            billboards: [
                {
                    title: 'Retail KBA Conversion Rate',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'salesSessionsConversionRatePreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    iconClass: 'fa-user-o fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'salesSessionsConversionRate',
                    metricMOMPropertyName: 'salesSessionsConversionRateMOM',
                    metricYOYPropertyName: 'salesSessionsConversionRateYOY',
                    metricPreviousMonthPropertyName: 'salesSessionsConversionRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'salesSessionsConversionRatePreviousMTD',
                    metricPreviousYearPropertyName: 'salesSessionsConversionRatePreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgSalesSessionsConversionRate',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'salesSessionsConversionRateMOM',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    helpTextKey: 'WebsiteScorecardSalesSessionsConversionRateHelp',
                    helpTextTitle: 'Retail KBA Conversion Rate',
                    scoreCardCategory: 'Conversion Rates'
                },
                {
                    title: 'Service KBA Conversion Rate',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'serviceSessionsConversionRatePreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    iconClass: 'fa-user-o fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'serviceSessionsConversionRate',
                    metricMOMPropertyName: 'serviceSessionsConversionRateMOM',
                    metricYOYPropertyName: 'serviceSessionsConversionRateYOY',
                    metricPreviousMonthPropertyName: 'serviceSessionsConversionRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'serviceSessionsConversionRatePreviousMTD',
                    metricPreviousYearPropertyName: 'serviceSessionsConversionRatePreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgServiceSessionsConversionRate',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'serviceSessionsConversionRateMOM',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    helpTextKey: 'WebsiteScorecardServiceSessionsConversionRateHelp',
                    helpTextTitle: 'Service KBA Conversion Rate',
                    scoreCardCategory: 'Conversion Rates'
                },
                {
                    title: 'Form Conversion Rate',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'formConversionRatePreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    iconClass: 'fa-commenting-o fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'formConversionRate',
                    metricMOMPropertyName: 'formConversionRateMOM',
                    metricYOYPropertyName: 'formConversionRateYOY',
                    metricPreviousMonthPropertyName: 'formConversionRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'formConversionRatePreviousMTD',
                    metricPreviousYearPropertyName: 'formConversionRatePreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgFormConversionRate',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'formConversionRateMOM',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    helpTextKey: 'WebsiteScorecardFormConversionRateHelp',
                    helpTextTitle: 'Form Conversion Rate',
                    scoreCardCategory: 'Conversion Rates'
                },

                {
                    title: 'Click to Call Rate',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'clickToCallRatePreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    iconClass: 'fa-commenting-o fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'clickToCallRate',
                    metricMOMPropertyName: 'clickToCallRateMOM',
                    metricYOYPropertyName: 'clickToCallRateYOY',
                    metricPreviousMonthPropertyName: 'clickToCallRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'clickToCallRatePreviousMTD',
                    metricPreviousYearPropertyName: 'clickToCallRatePreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgClickToCallRate',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'clickToCallRateMOM',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    helpTextKey: 'WebsiteScorecardClickToCallRateHelp',
                    helpTextTitle: 'Click to Call Rate',
                    scoreCardCategory: 'Conversion Rates'
                },

                {
                    title: 'Engagement Rate',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'engagementRatePreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    iconClass: 'fa-commenting-o fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'engagementRate',
                    metricMOMPropertyName: 'engagementRateMOM',
                    metricYOYPropertyName: 'engagementRateYOY',
                    metricPreviousMonthPropertyName: 'engagementRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'engagementRatePreviousMTD',
                    metricPreviousYearPropertyName: 'engagementRatePreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgEngagementRate',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'engagementRateMOM',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    helpTextKey: 'WebsiteScorecardEngagementRateHelp',
                    helpTextTitle: 'Engagement Rate',
                    scoreCardCategory: 'Conversion Rates'
                },
                {
                    title: 'Action Rate',
                    subtitle: 'Previous MTD',
                    subtitleMetricPropertyName: 'actionRatePreviousMTD',
                    subtitleMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    iconClass: 'fa-files-o fa-primary',
                    reverseMetric: false,
                    metricCurrentPropertyName: 'actionRate',
                    metricMOMPropertyName: 'actionRateMOM',
                    metricYOYPropertyName: 'actionRateYOY',
                    metricPreviousMonthPropertyName: 'actionRatePreviousMonth',
                    metricPreviousMTDPropertyName: 'actionRatePreviousMTD',
                    metricPreviousYearPropertyName: 'actionRatePreviousYear',
                    secondaryMetricPropertyName: 'dealerAvgActionRate',
                    secondaryMetricLabel: 'Dealer Avg',
                    secondaryMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    footerLeftLabel: 'POP',
                    footerRightLabel: 'YOY',
                    footerMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    trendArrowDrivingPropertyName: 'actionRateMOM',
                    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
                    helpTextKey: 'WebsiteScorecardActionRateHelp',
                    helpTextTitle: 'Action Rate',
                    scoreCardCategory: 'Conversion Rates'
                },
            ]
        }
    ];
}
